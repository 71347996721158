class LogoCarousel {

	constructor() {
		this.blocks = document.querySelectorAll('.block-logo-carousel');
		this.init();
	}

	init() {
		this.blocks.forEach((block) => {
			this.initSingle(block);
		});
	}

	initSingle(block){
		// swiper
		var swiper = new Swiper(block.querySelector('.swiper-container'), {
			slidesPerView: 3,
			spaceBetween: 32,
			loopedSlides: 10,
			loop: true,
			centerInsufficientSlides: true,
			autoplay: {
				delay: 3000,
			},
			breakpoints: {
				768: {
					slidesPerView: 4,
					spaceBetween: 64,
					loopedSlides: 15,
				},
				1024: {
					slidesPerView: 6,
					loopedSlides: 21
				}
			}
		});
	}
}

document.addEventListener('DOMContentLoaded', () => {
new LogoCarousel();
});
